<template>
  <TagPopover
    ref="refTagPopover"
    type="dropdown"
    :is-show="isShowPopover"
    :show-time="popoverShowTime"
    @hide="onHidePopover"
  >
    <slot></slot>

    <template #text>
      <div
        class="kid-tags__popover"
        @touchstart="onClick"
      >
        <div class="kid-tags__content">
          <div>
            <div
              ref="refLottieEl"
              class="kid-tags__icon"
            ></div>
          </div>

          <div class="kid-tags__text">
            {{ 
              isMultKids 
                ? language.SHEIN_KEY_PWA_34956 || 'Now you can create profiles for more kids'
                : language.SHEIN_KEY_PWA_32965 || ''
            }}
          </div>
        </div>

        <div
          v-if="isMultKids"
          class="kid-tags__btn"
        >
          <span>{{ language.SHEIN_KEY_PWA_34957 || 'Add More' }}</span>
        </div>
      </div>
    </template>  
  </TagPopover>
</template>

<script>
export default {
  name: 'KidPopover',
}
</script>

<script setup>
import { ref, watch, inject, computed, onMounted, onBeforeUnmount, nextTick } from 'vue'
import TagPopover from 'public/src/pages/components/FilterBar/components/popover/index.vue'
import { getIsShowKidsSimplePopoverByCache, getIsShowMultKidsPopoverByCache, isShowDefaultKidFilterToast } from 'public/src/pages/components/FilterBar/utils/kids.js'
import { useEventListener } from 'public/src/pages/components/FilterBar/hooks/useEventListener'
import UserInfoManager from 'public/src/services/UserInfoManager'
import { sleep } from 'public/src/pages/components/FilterBar/utils/index.js'
import { Toast as $toast } from '@shein/sui-mobile'

const emits = defineEmits(['hide', 'addMultKids'])
const props = defineProps({
  item: { type: Object, default: () => ({}) },
  isSpaPage: { type: Boolean, default: false },
  isSecondLoad: { type: Boolean, default: false },
  language: { type: Object, default: () => ({}) },
  isFirstIn: { type: Boolean, default: false },
  isMultKidsMode: { type: Boolean, default: false },
  isDefaultKidToast: { type: Boolean, default: false },
  isShowKidPopover: { type: Boolean, default: true },
  isShowAgeLimitDialog: { type: Boolean, default: false },
})
const filterBar = inject('filterBar')
const catInfo = inject('catInfo', {})

// 气泡类型
const KID_POPOVER_TYPE = {
  NONE: 'none', // 不展示
  SIMPLE: 'simple', // 新功能气泡提示
  MULT_KIDS: 'multKids', // 多孩填写气泡提示
}
const popoverType = ref(KID_POPOVER_TYPE.NONE) // 气泡类型【KID_POPOVER_TYPE】
const isTouchStart = ref(false) // 用户是否点击屏幕，如用户点击标签/滚动页面后，不展示气泡
const globPopModal = ref({ coupon: '', couponRevisit: '' }) // 监听券包弹窗【coupon/couponRevisit】【public/src/pages/common/glob_popup_modal】
const isShowOneClickPayModal = ref(false) //  是否展示一键购弹窗
const childListLength = computed(() => props.item?.child_list?.length || 0)
const isShowTotast = ref(false)
// const isListenGlobalPopModalQueue = ref(false) // 是否监听全局弹窗队列 TODO liao 测试是否需要该逻辑 
const isShowPopover = computed(() => popoverType.value !== KID_POPOVER_TYPE.NONE)
const popoverShowTime = computed(() => {
  if (popoverType.value === KID_POPOVER_TYPE.SIMPLE) return 3000
  if (popoverType.value === KID_POPOVER_TYPE.MULT_KIDS) return 5000

  return 0
})
const isMultKids = computed(() => popoverType.value === KID_POPOVER_TYPE.MULT_KIDS)
const isStorePage = computed(() => catInfo?.paegName === 'page_store')

function setIsShowToast () {
  if (props.isDefaultKidToast && !props.isShowAgeLimitDialog && !isShowOneClickPayModal.value && isShowDefaultKidFilterToast() ) {
    isShowTotast.value = true
    $toast(props.language.SHEIN_KEY_PWA_32974 || 'You can now create profiles for more kids')
  } else {
    isShowTotast.value = false
  }
}

function setPopoverType () {
  if (
    typeof window === 'undefined' || // ssr
    window.isBodyFixed || // body被锁
    isTouchStart.value || // 用户点击屏幕
    childListLength.value > 1 || // 儿童信息多余1条
    isShowOneClickPayModal.value ||  // 展示一键购弹窗
    props.isShowAgeLimitDialog || // 展示年龄限制弹窗
    props.isShowKidPopover === false || // 不展示标签
    !Object.values(globPopModal.value).every(val => val === false) // 展示券包弹窗
  ) {
    popoverType.value = KID_POPOVER_TYPE.NONE
    return
  }

  if (childListLength.value === 0) {
    popoverType.value = getIsShowKidsSimplePopoverByCache() 
      ? KID_POPOVER_TYPE.SIMPLE
      : KID_POPOVER_TYPE.NONE
    
    return
  }

  if(!props.isMultKidsMode) {
    popoverType.value = KID_POPOVER_TYPE.NONE
    return
  }

  if (isShowTotast.value) {
    popoverType.value = KID_POPOVER_TYPE.NONE
    return
  }

  if(props.isSecondLoad) {
    popoverType.value = KID_POPOVER_TYPE.MULT_KIDS
    return
  }

  popoverType.value = getIsShowMultKidsPopoverByCache() 
    ? KID_POPOVER_TYPE.MULT_KIDS
    : KID_POPOVER_TYPE.NONE
}

useEventListener('touchstart', () => {
  if (isTouchStart.value) return
  isTouchStart.value = true
  setPopoverType()
})

// 券包弹窗
const onGlobalPopModalQueueCallBack = obj => {
  const { name, isWait } = obj
  if (!['coupon', 'couponRevisit'].includes(name)) return

  globPopModal.value = {
    ...globPopModal.value,
    [name]: isWait
  }
}

const onGlobalPopModalQueue = () => {
  if (typeof window === 'undefined') return
  window._GB_PopUpModalEventCenter_.$on('global-pop-modal-queue', onGlobalPopModalQueueCallBack)
}
const offGlobalPopModalQueue = () => {
  if (typeof window === 'undefined') return
  window._GB_PopUpModalEventCenter_.$off('global-pop-modal-queue', onGlobalPopModalQueueCallBack)

}

// 一键购弹窗
function setIsShowOneClickPayModal (bannerTag) {
  if (!!bannerTag?.showTag != 1) {
    isShowOneClickPayModal.value = false
    return
  }
  const memberId =
  'oneClickPayGuid_' +
  UserInfoManager.get({
    key: 'memberId',
    actionType: 'searchResult/OneClickPayGuide'
  })

  isShowOneClickPayModal.value = localStorage.getItem(memberId) != 1
}

// product_list_v2容器，刚进来时，标签组件隐藏，不展示气泡
watch(() => props.isShowKidPopover, async () => {
  if (!props.isShowKidPopover || !props.isFirstIn) return

  // 店铺会自动吸顶，等待吸顶结束后再展示气泡
  await sleep(isStorePage.value  ? 1500 : 500)
  setPopoverType()
})

watch(filterBar, async () => {
  isTouchStart.value = false
  if (!props.isShowKidPopover || !props.isFirstIn) return
  
  setIsShowOneClickPayModal(filterBar.value?.filterData?.bannerTag)
  // 店铺会自动吸顶，等待吸顶结束后再展示气泡
  await sleep(isStorePage.value  ? 1500 : 500)
  setIsShowToast()
  setPopoverType()
})

const onHidePopover = () => {
  // isShow.value = false
  emits('hide')
}

const closeTagPopover = () => {
  refTagPopover.value?.hide()
}

const refLottieEl = ref()
const refTagPopover = ref()
const animationInstance = ref()
const onClick = async e => {
  e.stopPropagation()
  isTouchStart.value = true
  isMultKids.value && emits('addMultKids')
  await sleep(300) // 直接调用hide，可能会导致误点击商卡跳转商详
  closeTagPopover()
}

const showAnimation = async () => {  
  const [lottieWeb, lottieData] =  await Promise.all([
    import('lottie-web'),
    import('public/src/pages/components/FilterBar/CloudTags/components/TagItem/TagKids/lottie.json'),
    nextTick(),
  ])
  // 使用局部变量存储结果
  const lottieModule = lottieWeb.default
  const lottieJsonData = lottieData.default

  animationInstance.value = lottieModule?.loadAnimation({
    container: refLottieEl.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: lottieJsonData,
    name: 'kidPopoverIcon'
  })
}

const CLOST_KID_POPOVER = 'closeKidPopover'
const onCloseKidTagPopover = () => {
  window.appEventCenter.$on(CLOST_KID_POPOVER, closeTagPopover)
}
const offCloseKidTagPopover = () => {
  window.appEventCenter.$off(CLOST_KID_POPOVER, closeTagPopover)
}

onMounted(async () => {  
  onCloseKidTagPopover()
  if (props.isSpaPage === false) {
    showAnimation()
    onGlobalPopModalQueue()
    await sleep(1000 * 2)
    Object.keys(globPopModal.value).forEach(key => {
      if (globPopModal.value[key] === '') {
        globPopModal.value[key] = false
      }
    })
    setIsShowToast()
    setPopoverType()
  } else {
    await Promise.all([sleep(1000),  showAnimation()]) 
    globPopModal.value = { coupon: false, couponRevisit: false }
    setIsShowToast()
    setPopoverType()
  }
})

onBeforeUnmount(() => {
  offGlobalPopModalQueue()
  offCloseKidTagPopover()
  animationInstance.value?.destroy()
})
</script>

<style lang="less" scoped>
.kid-tags{
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    line-height: 14px;
  }

  &__btn {
    text-align: right;
    margin-top: 6px;

    span {
      display: inline-block;
      max-width: 100%;
      height: 24px;
      padding: 4px 12px;
      border-radius: 25px;
      color: #fff;
      line-height: 24px;
      border: 0.5px solid #fff;
      font-size: 13px;
      font-weight: 600;
      line-height: 15.51px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

</style>
