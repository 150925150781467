
import Vue from 'vue'
import KidsFormModal from './Container.vue'
import { KIDS_MODAL_MODE } from 'public/src/pages/components/FilterBar/components/kids-form-modal/utils.js'

const Comp = Vue.extend(KidsFormModal)

export const showKidsFormModal = (params = {}) => {
  if (typeof window === 'undefined') {
    return
  }
  const { childInfo, language, isMultKidsMode, curKidsNum, maxKidsNum } = params
  let kidModalMode = KIDS_MODAL_MODE.ADD_SIMPTE
  
  if (!!childInfo) {
    (kidModalMode = KIDS_MODAL_MODE.UPDATE)
  } else if (isMultKidsMode) {
    kidModalMode = KIDS_MODAL_MODE.ADD_MULT
  } else {
    kidModalMode = KIDS_MODAL_MODE.ADD_SIMPTE
  }
  
  const instance = new Comp({ propsData: { childInfo, language, kidModalMode, curKidsNum, maxKidsNum } }).$mount()

  const destroy = () => {
    window.setTimeout(() => {
      instance.$destroy()
    }, 100)
  }

  return new Promise((resolve) => {
    instance.$on('submit', (data) => {
      // 新增/编辑
      resolve({ action: 'SAVE', data })
      destroy()
    })
    instance.$on('close', () => {
      // 无操作，关闭
      resolve({ action: 'CLOSE' })
      destroy()
    })
    instance.$on('delete-success', () => {
      // 删除
      resolve({ action: 'DELETE' })
      destroy()
    })
  })
}
