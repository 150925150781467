
import { onMounted, provide, ref } from 'vue'
import { validateKid, getDefaultKid } from 'public/src/pages/components/FilterBar/components/kids-form-modal/utils.js'

export const useKidsFormData = ({ language }) => {
  const kidList = ref([])

  const getKidById = id => kidList.value.find(item => item.id === id)
  const getAllKids = () => kidList.value
  const addKid = () => {
    kidList.value.push(getDefaultKid(language)) 
  }
  const kidRemove = id => (kidList.value = kidList.value.filter(kid => kid.id !== id))
  const updateKidBirthday = (data, id) => {
    const kid = getKidById(id)
    if (!kid) return

    kid.month = data.month
    kid.year = data.year
  }

  const initKid = (data) => {
    const kid = getDefaultKid(language)

    if (!data?.child_id) {
      kidList.value = [kid]
      return
    }
    
    kid.nickname = data.nickname || ''
    const [year, month] = data.birthday?.split('-') || []
    kid.year = year || ''
    kid.month = month || ''
    kid.sex = String(data.sex || '') || '2'
    validateKid(kid, language)
    kidList.value = [kid]
  }

  // 校验所有kid
  const validateAllKid = () => {
    let flag = true
    kidList.value.forEach(kid => {
      if (!validateKid(kid, language)) {
        flag = false
      }
    })

    return flag
  }

  provide('KIDS_FORM_MODAL', {
    kidList,
    addKid,
    initKid,
    kidRemove,
    updateKidBirthday
  })

  return {
    kidList,
    getAllKids,
    getKidById,
    addKid,
    initKid,
    validateAllKid
  }
}

export const useAndroidKeyboard = () => {

  const showFooter = ref(true)

  onMounted(() => {
    const isAndroid = typeof window !== 'undefined' && navigator.userAgent?.toLowerCase().indexOf('android') > -1
    const winH = window.innerHeight
    window.addEventListener('resize', () => {
      if (!isAndroid) {
        return
      }
      const newInnerHeight = window.innerHeight
      if (winH > newInnerHeight) {
        showFooter.value = false
      } else {
        showFooter.value = true
      }
    })
  })

  return {
    showFooter,
  }
}
