<template>
  <div :class="`kids-modal-header kids-modal-header__${kidModalMode}`"></div>
</template>

<script>

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'KidsModalHeader',
  props: {
    kidModalMode: {
      type: String,
      default: '',
    }
  },
})
</script>

<style lang="less" scoped>
.kids-modal-header {
    height: 32px;
    position: sticky;
    top: 0;
    z-index: 1;
    transform: translate3d(0,0,1);
    
  &__addMult {
    background: #f5f5f5;
  }
}
</style>
