<template>
  <div
    :class="{
      'kid-add__btn': true,
      'kid-add__disabled': isDisabled
    }"
    @click="onClick"
  >
    <Icon
      name="sui_icon_add_16px"
      size="16px"
    />
    <span>
      {{ 
        isDisabled 
          ? (language.SHEIN_KEY_PWA_32919 || 'Maximum 10 profiles allowed')
          : (language.SHEIN_KEY_PWA_34958 || 'Add another Kid')
      }}
    </span>
  </div>
</template>

<script setup>
import { inject, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

const props = defineProps({
  curKidsNum: { type: Number, default: 1 },
  maxKidsNum: { type: Number, default: 1 },
  language: { type: Object, default: () => ({}) },
})

const { kidList, addKid } = inject('KIDS_FORM_MODAL', {})
const isDisabled = computed(() => (kidList.value?.length + props.curKidsNum) >= props.maxKidsNum)
const onClick = () => !isDisabled.value && addKid()

</script>

<style lang="less" scoped>
.kid-add {
  &__btn{
    width: 100%;
    padding: 15.5px 0 15.5px 12px;
    gap: 6px;
    display: flex;
    font-size: 14px;
    align-items: center;
    color: rgba(45, 104, 168, 1);
    background: #fff;
    margin-bottom: (100 + 12 + 44 + 12) / 37.5rem;
  }

  &__disabled {
    color: rgba(204, 204, 204, 1)
  }

}
</style>
