<template>
  <s-drawer
    :visible="visible"
    max-size="80vh"
    direction="btt"
    append-to-body
    @close-from-mask="closeModal"
  >
    <div
      class="kids-modal"
      :class="`kids-modal__${kidModalMode}`"
    >
      <div class="kids-modal__container">
        <ModalHeader 
          :kid-modal-mode="kidModalMode" 
        />

        <ModalBanner
          :language="language"
          :kid-list="kidList"
          :kid-modal-mode="kidModalMode" 
          @close-modal="closeModal"
        />

        <ModalCloseIcon 
          :kid-modal-mode="kidModalMode" 
          @close-modal="closeModal"
        />
        
        <div class="kids-modal__content">
          <KidsForm 
            v-for="(kid, index) in kidList" 
            :key="kid.id"
            :kid="kid"
            :kid-index="index + 1"
            :kid-list="kidList"
            :cur-kids-num="curKidsNum"
            :max-kids-num="maxKidsNum"
            :kid-modal-mode="kidModalMode" 
          />
            
          <KidAddBtn 
            v-if="isShowKidAddBtn"
            :language="language"
            :cur-kids-num="curKidsNum"
            :max-kids-num="maxKidsNum"
          />
        </div>
      </div>
        
      <ModalFooter
        :child-id="childInfo.child_id"
        :show-footer="showFooter"
        :kid-modal-mode="kidModalMode" 
        @delete-success="onDeleteSuccess"
        @save="handleSave"
      />
    </div>
  </s-drawer>
</template>

<script>
/**
 * @file kids信息采集弹窗
 */

import { defineComponent, onMounted, ref, provide, computed, getCurrentInstance, onBeforeUnmount, nextTick } from 'vue'

import { Drawer as SDrawer, Toast as $toast } from '@shein/sui-mobile'

import ModalHeader from './ModalHeader.vue'
import ModalBanner from './ModalBanner.vue'
import ModalCloseIcon from './ModalCloseIcon.vue'
import KidsForm from './KidsForm.vue'
import ModalFooter from './ModalFooter.vue'
import KidAddBtn from './KidAddBtn.vue'

import { useKidsFormData, useAndroidKeyboard } from './hooks'
import { addChildInfo, modifyChildInfo } from './api'
import { KIDS_MODAL_MODE, toastInit, toastShow, toastRemove } from 'public/src/pages/components/FilterBar/components/kids-form-modal/utils.js'

export default defineComponent({
  name: 'KidsFormModal',
  components: {
    SDrawer,
    ModalHeader,
    KidsForm,
    KidAddBtn,
    ModalFooter,
    ModalBanner,
    ModalCloseIcon
  },
  props: {
    childInfo: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    kidModalMode: {
      type: String,
      default: '',
    },
    curKidsNum: {
      type: Number,
      default: 1
    },
    maxKidsNum: {
      type: Number,
      default: 1
    },
  },
  setup(props, { emit }) {

    provide('KIDS_CONTEXT', {
      getLanguage: () => props.language,
    })

    const {
      showFooter,
    } = useAndroidKeyboard()
    const { kidList, initKid, getAllKids, validateAllKid } = useKidsFormData({ language: props.language })
  

    const visible = ref(false)

    const closeModal = () => {
      visible.value = false

      emit('close')
    }

    const loading = ref(false)

    const handleSave = async () => {
      if (!validateAllKid()) {
        $toast(props.language.SHEIN_KEY_PWA_32893)
        await nextTick()
        document.querySelector('.kids-form-item__error')?.scrollIntoView?.({
          behavior: 'smooth',
          block: 'center'
        })
        return
      }
      loading.value = true
      const child_list = getAllKids().map(kid => ({
        birthday: `${kid.year}-${kid.month}`,
        nickname: kid.nickname,
        sex: Number(kid.sex),
      }))
      
      if (props.childInfo.child_id && child_list[0]) {
        child_list[0].child_id = props.childInfo.child_id
      }

      const isUpdate = props.kidModalMode === KIDS_MODAL_MODE.UPDATE
      const api = isUpdate ? modifyChildInfo : addChildInfo
      const data = await api({ child_list })
      loading.value = false
      if (String(data.code) === '0') {
        !isUpdate && data?.info?.child_id_list?.forEach?.((id, index) => child_list[index].child_id = id)
        toastShow(child_list[child_list.length - 1]?.nickname, props.language.SHEIN_KEY_PWA_34959 || 'Ta-da! Here are {0}\'s personalized picks')
        visible.value = false
        emit('submit', {
          child_list,
        })
      } else {
        $toast(props.language.SHEIN_KEY_PWA_32889)
      }
    }

    const onDeleteSuccess = () => {
      visible.value = false
      emit('delete-success')
    }

    const isShowKidAddBtn = computed(() => props.kidModalMode === KIDS_MODAL_MODE.ADD_MULT)

    onMounted(() => {
      window.$cc = getCurrentInstance()
      initKid(props.childInfo)
      visible.value = true
      toastInit()
    })

    onBeforeUnmount(() => toastRemove())

    return {
      kidList,
      showFooter,
      visible,
      loading,
      closeModal,
      handleSave,
      isShowKidAddBtn,
      onDeleteSuccess,
    }
  }
})
</script>

<style lang="less" scoped>
.kids-modal {
  width: 100vw;
  height: 80vh;
  background-color: #fff;
  position: relative;
  overflow: hidden;

  &__container{
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  &__addMult {
    background: rgba(246, 246, 246, 1);

    &__container{
      // padding-top: 32px;
    }

    .kids-modal__content {
      padding-left: 12px;
      padding-right: 12px;


    }
  }
}

</style>
