<template>
  <div v-expose="item.exposeData">
    <TagButtonContainer
      :key="item.value"
      :is-dot="item.isDot"
      :is-show-popover="item.isShowPopover"
      :popover-text="item.popoverText"
      :active="cccTextData.active"
      :styles="cccTextData.styles"
      @click="onClick"
    >
      <div class="tag-cloud__ccc-text">
        <img
          v-if="cccTextData.icon"
          :src="cccTextData.icon"
        />

        <span>{{ cccTextData.label }}</span>
      </div>
    </TagButtonContainer>
  </div>
</template>

<script>
export default {
  name: 'TagCccText',
}
</script>

<script setup>
import { toRef, inject } from 'vue'

// components
import TagButtonContainer from '../../TagButtonContainer/index.vue'

// hooks
import { useCccTextData } from './hooks.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { FILTER_RESULT_TYPE_MAP } from 'public/src/pages/components/FilterBar/utils/const.js' 

const emits = defineEmits(['click', 'change'])
const props = defineProps({ item: { type: Object, default: () => ({}) } })

const queryParams = inject('queryParams', {})
const fhContext = inject('fhContext', {})
const googleContext = inject('googleContext', {})
const cccTextData = useCccTextData(toRef(props, 'item'), queryParams)

const onClick = () => {
  emits('click', props.item)
  emitCloseDropdown()
  const item = props.item
  const { value, tagIndex } = props.item
  const isCancel = Number(value === queryParams.value?.tag_ids)
  const tag_ids = cccTextData.value.active ? '' : cccTextData.value.value

  analysis.clickCloudTag({
    tagId: value,
    tagCate: 'label',
    tagIndex: tagIndex + 1,
    isCancel,
    facet: fhContext?.value?.tag_id,
    gfilter: googleContext?.value?.gfilter,
    isPicNavCate: false
  })
  emits('toStickyByClick')
  emits('change', {
    item,
    filterResult: {
      type: FILTER_RESULT_TYPE_MAP.CLOUD_TAGS,
      label_id: cccTextData.value.value,
      isDelete: !tag_ids,
      label: cccTextData.value.label,
    },
    params: {
      tag_ids,
      source: 'cloudTag',
      source_status: tag_ids ? 1 : 0,
      mall_tag: tag_ids ? props.item?.mall_tag : '',
    }
  })
}
</script>

<style scoped lang="less">
.tag-cloud {
  &__ccc-text {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
}
</style>
