
import schttp from 'public/src/services/schttp'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'

// 是否走bff
function isUseBffApi () { 
  return isSwitchBffApiVersion(['selection_v1'])?.selection_v1
}

// 新增 child
const addChildInfoByNode = async (params) => {
  return await schttp({
    url: '/api/member/child/add',
    method: 'POST',
    data: {
      child_list: params.child_list,
    }
  })
}

const addChildInfoByBff = async (params) => {
  return await schttp({
    // url: '/category/tag/child/add_child_infos',
    url: '/category-api/tag/child/add-child-infos',
    method: 'POST',
    useBffApi: true,
    data: { child_infos: params.child_list }
  }).then(res => { 
    return {
      code: res?.code,
      info: {
        child_id_list: (res?.info?.child_infos ?? []).map(child => child.child_id)
      }
    }
  })
}

export const addChildInfo = params => isUseBffApi()
  ? addChildInfoByBff(params)
  : addChildInfoByNode(params)

// 删除 child
const deleteChildInfoByNode = async (params) => {
  return await schttp({
    url: '/api/member/child/delete',
    method: 'POST',
    data: {
      child_id: params.child_id,
    }
  })
}
const deleteChildInfoByBff = async (params) => {
  return await schttp({
    url: '/category/tag/child/delete_child_info',
    method: 'POST',
    params,
    useBffApi: true
  })
}
export const deleteChildInfo = async (params) => isUseBffApi()
  ? deleteChildInfoByBff(params)
  : deleteChildInfoByNode(params)

// 更新 child
const modifyChildInfoByNode = async (params) => {
  return await schttp({
    url: '/api/member/child/modify',
    method: 'POST',
    data: {
      child_list: params.child_list,
    }
  })
}
const modifyChildInfoByBff = async (params) => {
  return await schttp({
    url: '/category/tag/child/modify_child_info',
    method: 'POST',
    useBffApi: true,
    params: params.child_list?.[0] || {}
  })
}
export const modifyChildInfo = (params) => isUseBffApi()
  ? modifyChildInfoByBff(params)
  : modifyChildInfoByNode(params)
