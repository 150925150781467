<template>
  <div :class="`kids-modal-banner kids-modal-banner__${kidModalMode}`">
    <img
      class="kids-modal__bg-img"
      :src="bgImgSrc"
    />
    <div class="kids-modal__banner-content">
      <div class="kids-modal__banner-title">
        {{ isBirthdayOver ? language.SHEIN_KEY_PWA_32975 : language.SHEIN_KEY_PWA_33092 }}
      </div>
      <div class="kids-modal__banner-desc">
        {{ isBirthdayOver ? language.SHEIN_KEY_PWA_33020 : language.SHEIN_KEY_PWA_32965 }}
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, computed } from 'vue'
import { KIDS_MODAL_MODE } from 'public/src/pages/components/FilterBar/components/kids-form-modal/utils.js'

export default defineComponent({
  name: 'ModalBanner',
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    kidList: {
      type: Array,
      default: () => [],
    },
    kidModalMode: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {

    const bgImgSrc = computed(() => {
      return props.isBirthdayOver ?
        '//img.ltwebstatic.com/images3_ccc/2024/08/02/6c/17225906031c23d427a44a2101f7726ef7b7ce26fb.png' :
        '//img.ltwebstatic.com/images3_ccc/2024/12/09/4d/1733714679c6f73373f0629a8bb526c971bc3b91ee.png' 
        
    })

    const closeModal = () => {
      emit('close-modal')
    }

    const isBirthdayOver = computed(() => {
      if(props.kidModalMode === KIDS_MODAL_MODE.ADD_MULT)  return false

      return props.kidList?.[0]?.isBirthdayOver
    })
    
    return {
      bgImgSrc,
      closeModal,
      isBirthdayOver
    }
  }
})
</script>

<style lang="less" scoped>
.kids-modal {
  &-banner {
    min-height: 3.25rem;
    margin-bottom: 12px;
    position: relative;
    z-index: 2;
    margin-top: -32px;
  }

  &__bg-img {
    width: 100%;
    height: 3.25rem;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__banner-content {
    position: relative;
    height: 3.25rem;
    line-height: 1.4;
    padding-left: 16px;
    max-width: 5.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__banner-title {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__banner-desc {
    color: rgba(0, 0, 0, 0.60);
    font-size: 12px;
    font-weight: 400;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

[mir="rtl"] {
  .kids-modal__bg-img {
    transform: rotateY(180deg);
  }
}
</style>
